import { React, useState } from "react";
import { forwardRef } from "react";
import axios from "axios";
import "./_sectionone.css";
import ExplorugVideo from "../ExplorugVideo/explorugvideo";

const SectionOne = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();

  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.append("event", "buttonClicked,learnMore ");
    axios.post(explorugurl, formdata1).then((success) => {});
    setLearnMore(true);
  }
  const [learnmore, setLearnMore] = useState(false);

  return (
    <>
      {/* <div ref={ref} className="section-one-container section ">
        <div className="image-wrapper">
          <div
            className="section-one-text text-white text-align-center"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h1 className="title">
              Digital Platform for Promoting Ancient Craft
            </h1>
            <p className="subtitle">
              The most versatile online engine in the world for selling custom
              rugs and carpets
            </p>
            <ExplorugVideo learnmore={learnmore} />
          </div>
        </div>
      </div> */}
      <div ref={ref} className="section-one-container section ">
        <div className="image-wrapper">
          <div className="section-one-text text-white ">
            <div className="wrappertitle">
              <h1 className="title">
                Digital Platform for Promoting Ancient Craft
              </h1>
              <p className="subtitle">
                The most versatile online engine in the world for selling custom
                rugs and carpets
              </p>
              <p className="subtitle-mid">
                The most versatile online engine
                <br /> in the world
                <br /> for selling custom rugs and carpets
              </p>
            </div>

            <ExplorugVideo learnmore={learnmore} />
          </div>
        </div>
      </div>
    </>
  );
});

export default SectionOne;
