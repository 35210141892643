import React, { useRef, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import "./_rejuvenateyourshowroom.css";
const RejuvenateYourShowroom = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.delete("event", e.target.getAttribute("data-event"));
    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }
  const elementRef = useRef(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollToElement = urlParams.get("scrollToElement");
    if (scrollToElement === "true" && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
      console.log(elementRef.current);
    } else {
      console.log("here");
      // window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <div ref={elementRef}>
        <div className="container"></div>
        <div
          ref={ref}
          className=" section rejuvenateyourshowroom-wrapper text-white container-fluid  "
        >
          <a
            href="https://v3.explorug.com/explorug.html?page=ruglife&initdesign=Cubinia"
            target="_blank"
          >
            <div
              className="hotspots"
              title="Click here to try exploRUG"
              data-event="imageClicked, exploRUGDemo"
              onClick={(e) => postToPage(e)}
            ></div>
          </a>
          {/* <img className="rejuvenateyourshowroom-wrapper"
          src="https://cdn.explorug.com/website/explorug/TV-Screen.jpg"
          usemap="#workmap"
        />
        <map name="workmap">
          <area
            shape="poly"
            coords="34,5000,270,350"
            alt="TV"
            href="computer.htm"
          />
        </map> */}
          {/* <a href="https://explorug.com/demo" className="clickable-area"></a> */}
          <div className="row">
            <div
              className="rejuvenateyourshowroom-text-one col-md-6"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="10"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-once="true"
            >
              <h1 className="rejuvenate-h1-mobile">
                Rejuventate your showroom
              </h1>
              <p className="rejuvenate-para1">
                exploRUG can be configured as your digital showroom on a smart{" "}
                <br />
                TV within 10 sq ft area containing all your designs ready to be
                <br /> customized{" "}
              </p>
              <p className="myroom-para1-tablet">
                exploRUG can be configured as your
                <br />
                digital showroom on a smart TV within <br /> 10 sq ft area
                containing all your designs
                <br /> ready to be customized{" "}
              </p>
              <a
                className="gray"
                href="https://go.explorug.com/1m2-showroom"
                target="_blank"
                data-event="linkClicked, 1m2Showroom"
                onClick={(e) => postToPage(e)}
              >
                Learn about the 1m<sup>2</sup> showroom
              </a>
            </div>
            <div className="col-md-6">
              <p className="rejuvenateyourshowroom-text-two">
                Smart TV running EXPLORUG on browser and use remote pointer for
                interaction
                <br />
                Please click on TV screen for live experience of exploRUG
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default RejuvenateYourShowroom;
