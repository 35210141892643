import "./_explorugvideo.css";
const ExplorugVideo = (learnmore) => {
  console.log(learnmore);
  return (
    <>
      <div
        id="explorugvideo"
        className="explorugvideosection container learnmoreclick  "
      >
        <div className="row">
          <div className="explorugvideo col-md-6 col-lg-12 ">
            {/* <iframe
              className="iframe "
              src="https://www.youtube.com/embed/LXNGscO62Vs?&autoplay=1&mute=1&rel=0"
              title="Explorug Overview"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              
            ></iframe> */}
            <iframe
              className="iframe"
              src="https://www.youtube.com/embed/LXNGscO62Vs?autoplay=1&mute=1&rel=0&loop=1&playlist=LXNGscO62Vs"
              title="Explorug Overview"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* {!learnmore && (
        <div className="explorugvideosection container  ">
          <div className="row">
            <div className="explorugvideo col-md-6 col-lg-12 ">
              <iframe
                className="iframe "
                src="https://www.youtube.com/embed/LXNGscO62Vs?&autoplay=1&mute=1&rel=0"
                title="Explorug Overview"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
export default ExplorugVideo;
