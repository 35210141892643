import React from "react";
import { forwardRef } from "react";
import axios from "axios";
import "./_revolutionalizeyourpresence.css";
const RevolutionalizeYourSocialmediaPresence = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.delete("event", e.target.getAttribute("data-event"));

    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }

  return (
    <>
      <div
        ref={ref}
        className=" section revolutionalize-wrapper container-fluid d-flex text-white d-flex"
      >
        <div className="row">
          <div
            className="revolutionalize-text col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="revolutionalize-h1-laptop">
              Revolutionalize your <br /> social media presence
            </h1>
            <p className="revolutionalize-para-1">
              Your private messages or public posts
              <br />
              can be easily empowered by exploRUG, <br />
              making them interactive marketing and
              <br />
              communication medium
            </p>
            <p className="revolutionalize-para-tablet">
              Your private messages or public
              <br /> posts
              <br />
              can be easily empowered by
              <br /> exploRUG, <br />
              making them interactive
              <br /> marketing and communication
              <br /> medium
            </p>
            <p className="revolutionalize-para-lessthantablet">
              Your private messages or public posts
              <br />
              can be easily empowered by
              <br /> exploRUG, <br />
              making them interactive marketing <br /> and communication medium
            </p>

            <h2 className="socialmedia-text">
              {" "}
              Try sharing this design on your social media
            </h2>
            <div className="socialmediaicons">
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/linkedin"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__04.png"
                  data-event="socialMediaClicked, linkedIn"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/facebook"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__01.png"
                  data-event="socialMediaClicked, facebook"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/twitter"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__02.png"
                  data-event="socialMediaClicked, twitter"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/pinterest"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__03.png"
                  data-event="socialMediaClicked, pinterest"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="mailto:?subject=Customize%20My%20Rug&body=%20Please%20visit%20https://v3.explorug.com/RUGShare/9349E1D53DF5412E4A3961F68EA1313E/D9FF56%20to%20customize%20my%20rug.%0D%0A%0D%0APowered%20by%20exploRUG.com%20and%20RUGShare."
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__05.png"
                  data-event="socialMediaClicked, email"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
            </div>
            {/* <iframe  src="./new/RUGShare-social-button.html" ></iframe> */}
          </div>

          <div
            className="revolutionalize-image col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="revolutionalize-h1-mobile">
              Revolutionalize your <br /> social media presence
            </h1>
            <a href="https://explor.ug/dream-kaleen" target="_blank">
              <img
                className="revolutionalize-img "
                src="https://cdn.explorug.com/website/explorug/RUGshare-DreamKaleen-min.jpg"
                alt="RUGshare-DreamKaleen-min.jpg"
                data-event="imageClicked, RUGShare"
                onClick={(e) => {
                  postToPage(e);
                }}
              />
            </a>

            <center className="revolutionalize-description">
              Customize this design and then share it
            </center>
          </div>
          <div
            className="revolutionalize-text-mobile col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <p className="revolutionalize-text-paragraph">
              Your private messages or public posts
              <br />
              can be easily empowered by exploRUG, <br />
              making them interactive marketing and communication medium
            </p>

            <h2 className="socialmedia-text">
              {" "}
              Try sharing this design on your social media
            </h2>

            <div className="socialmediaicons">
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/linkedin"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__04.png"
                  data-event="socialMediaClicked, linkedIn"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/facebook"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__01.png"
                  data-event="socialMediaClicked, facebook"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/twitter"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__02.png"
                  data-event="socialMediaClicked, twitter"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="https://go.explorug.com/pinterest"
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__03.png"
                  data-event="socialMediaClicked, pinterest"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
              <a
                className="socialmediaicon"
                href="mailto:?subject=Customize%20My%20Rug&body=%20Please%20visit%20https://v3.explorug.com/RUGShare/9349E1D53DF5412E4A3961F68EA1313E/D9FF56%20to%20customize%20my%20rug.%0D%0A%0D%0APowered%20by%20exploRUG.com%20and%20RUGShare."
                target="_blank"
              >
                <img
                  src="http://rugshare.net/images/social_b__05.png"
                  data-event="socialMediaClicked, gmail"
                  onClick={(e) => {
                    postToPage(e);
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default RevolutionalizeYourSocialmediaPresence;
