import React from "react";

import { forwardRef } from "react";
import axios from "axios";
import "./_myroomsection.css";

const MyRoomSection = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.delete("event", e.target.getAttribute("data-event"));
    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }

  return (
    <>
      <div
        ref={ref}
        id="myroom"
        className=" section myroomsection-wrapper container-fluid text-white d-flex "
      >
        <div className="row">
          <div
            className="   col-md-6  myroomsection-image-mobile"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="myroom-h1-mobile"> Lay rugs in any room using AI</h1>
            <a href="https://go.explorug.com/myroom" target="_blank">
              <img
                className="w-100"
                src="https://cdn.explorug.com/website/explorug/MyRoom-New-Interface-New.png"
                title="Click here to lay this rug in your own room"
                data-event="imageClicked, myRoom"
                onClick={(e) => {
                  postToPage(e);
                }}
              />
            </a>
          </div>
          <div
            className="  col-md-6  myroomsection-text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="myroom-h1-laptop"> Lay rugs in any room using AI</h1>
            <p className="myroom-para1">
              With our revamped My Room, you can take a photo of any room
              <br />
              and the AI will do its magic to lay a rug in that room for you.
              <br />
              You can adjust the position of the rug and also view <br />{" "}
              different rugs in the same room.
            </p>
            <p className="myroom-para1-tablet">
              With our revamped My Room, you can <br />
              take a photo of any room
              <br />
              and the AI will do its magic to lay a rug <br /> in that room for
              you.
              <br />
              You can adjust the position of the rug
              <br /> and also view <br /> different rugs in the same room.
            </p>
            <a
              className="gray"
              href="https://go.explorug.com/myroom"
              target="_blank"
              data-event="linkClicked, myRoom"
              onClick={(e) => {
                postToPage(e);
              }}
            >
              {" "}
              Lay this rug in your own room
            </a>
          </div>
          <div
            className="   col-md-6  myroomsection-image"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <a href="https://go.explorug.com/myroom" target="_blank">
              <img
                className="myroom-image"
                src="https://cdn.explorug.com/website/explorug/MyRoom-New-Interface-New.png"
                title="Click here to lay this rug in your own room"
                data-event="imageClicked, myRoom"
                onClick={(e) => {
                  postToPage(e);
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
});
export default MyRoomSection;
