import React from "react";

function ContactPage() {
  return (
    <div style={{ paddingTop: "3.5rem", background: "#242424" }}>
      <explorug-contact
        themeColor="#00d092"
        backgroundColor="#242424"
        textColor="white"
        product="Explorug"
      ></explorug-contact>
    </div>
  );
}

export default ContactPage;
