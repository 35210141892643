import React, { useState } from "react";
import { useEffect } from "react";
import "./_twoscrollbutton.css";

const TwoScrollButton = ({}) => {
  const [show, setShow] = useState(false);

  const handleGoToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const scrollToTop = () => {
      const { scrollTop } = document.documentElement;

      if (scrollTop >= 0.5) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener("scroll", scrollToTop);

    return () => window.removeEventListener("scroll", scrollToTop);
  }, []);
  return (
    <>
      {show && (
        <div
          id="scrollbutn"
          className="arrow-container"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          style={{ cursor: "pointer" }}
          onClick={handleGoToTop}
        >
          <div className="">
            <span className="scrollbutn">
              <i className={`fa fa-angle-up "}`}></i>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TwoScrollButton;
