import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import RejuvenateYourShowroom from "../../components/views/RejuvenateYourShowroom/rejuvenateyourshowroom";

import Header from "../../components/views/ExplorugHeader/explorugheader";
import MyRoomSection from "../../components/views/MyRoomSection/myroomsection";
import RemodelYourWebsite from "../../components/views/RemodelYourWebsite/remodelyourwebsite";
import SectionOne from "../../components/views/SectionOne/sectionone";
import RevolutionalizeYourSocialmediaPresence from "../../components/views/RevolutionizeYourSocialMediaPresence/revolutionalizeyoursocialmediapresence";
import ReincarnateyourPromotionalMaterials from "../../components/views/ReincarnateYourPromotionalMaterials/reincarnateyourpromotionalmaterials";
import RealisticVisualization from "../../components/views/RealisticVisualization/realisticvisualization";
import TwoScrollButton from "../../components/views/TwoScrollButton/twoscrollbutton";
import ExplorugVideo from "../../components/views/ExplorugVideo/explorugvideo";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import "../../App.css";
const HomePage = () => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  formdata1.append("event", "initialLoad");

  const [modal, setModal] = useState(false);
  const handleClose = () => {
    setModal(false);
  };
  var formdata = new FormData();
  formdata.append("product", "explorug");
  function postToPage(e) {
    formdata.delete("event", e.target.getAttribute("data-event"));
    formdata.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata).then((success) => {});
  }

  useEffect(() => {
    // setModal(true);
    window.addEventListener("load", () => {
      axios.post(explorugurl, formdata1).then((success) => {});
      // window.scrollTo(0, 0);
    });
    return () => {
      window.removeEventListener("load", () => {
        // window.scrollTo(0, 0);
      });
    };
  }, []);

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowRugPopup(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //.......rugstudio modal...........
  const [isMobile, setIsMobile] = useState(false);
  const [showRugPopup, setShowRugPopup] = useState(true);
  const popupRef = useRef();
  const images = [
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Micrograph in Loft Xenonim S4.jpg",
      title: "Loft Xenonim",
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Apostlute in Bedroom Nanoscape S2D.jpg",
      title: "Bedroom Nanoscape",
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Atlantio in Kitchen Dining Pharong.jpg",
      title: "Kitchen Dining Pharong",
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Indephon in Living Room Mapolis S2.jpg",
      title: "Living Room Mapolis",
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Solology in Living Room Vianalon S1V2.jpg",
      title: "Living Room Vianalon",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3500);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [images.length]);

  const handleSubmitTryNow = () => {
    const url = "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
    var formdata = new FormData();
    formdata.append("product", "explorug");
    formdata.append("event", "promotionLinkClicked, RugStudioLoginPage");
    axios.post(url, formdata).then((success) => {});
    window.open("https://myrug.studio/", "__blank");
  };
  return (
    <>
      {showRugPopup && (
        <div className="promotionalModal">
          <div
            className={classNames("promotionalContainer", { isMobile })}
            ref={popupRef}
          >
            <span
              className="icon-close"
              onClick={() => setShowRugPopup(false)}
              style={{ cursor: "pointer" }}
            >
              &times;
            </span>

            <div className={classNames("logo", { isMobile })}>
              <img
                src="https://emotion.explorug.com/MyRugStudioAssets/svg/utils/logo.svg"
                alt="myrugstudio"
              ></img>
            </div>
            <div className={classNames("about", { isMobile })}>
              <span>Discover our diverse 3D environments</span>
              <span>and find the perfect room for your rug.</span>
            </div>
            <div className={"carousel"}>
              <ul className="list">
                {images.map((image, index) => {
                  let className = "hide";
                  if (index === currentIndex) {
                    className = "act";
                  } else if (
                    index ===
                    (currentIndex - 2 + image.length) % image.length
                  ) {
                    className = "prev old-prev";
                  } else if (
                    index ===
                    (currentIndex - 1 + images.length) % images.length
                  ) {
                    className = "prev";
                  } else if (index === (currentIndex + 1) % images.length) {
                    className = "next";
                  } else if (index === (currentIndex + 2) % images.length) {
                    className = "next new-next";
                  }

                  return (
                    <li
                      key={index}
                      className={classNames(className, { isMobile })}
                    >
                      <img src={image.link} alt={`Slide ${index + 1}`} />
                    </li>
                  );
                })}
              </ul>

              <div className={classNames("controller-button", { isMobile })}>
                <button
                  onClick={() => {
                    setCurrentIndex(
                      (prevIndex) => (prevIndex + 1) % images.length
                    );
                    clearInterval(intervalId);
                  }}
                >
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 18L9 12L15 6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    setCurrentIndex(
                      (prevIndex) =>
                        (prevIndex - 1 + images.length) % images.length
                    );
                    clearInterval(intervalId);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18L15 12L9 6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className={classNames("description", { isMobile })}>
                {images[currentIndex].title}
              </div>
            </div>

            <div className={classNames("upgrade", { isMobile })}>
              Upgrade your exploRUG with more rooms
            </div>
            <div className={classNames("contact", { isMobile })}>
              <button onClick={handleSubmitTryNow}>Try Now</button>

              <div>
                For any details, contact us at{" "}
                <u
                  onClick={() =>
                    (window.location = "mailto:info@galaincha.com.np")
                  }
                >
                  info@galaincha.com.np
                </u>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Christmas and black friday modal */}
      {/* <Modal
        show={modal}
        onHide={handleClose}
        size="xl"
        className="initial-pop-up"
        centered
      >
        <video
          src="https://cdn.explorug.com/Website/Galaincha/login/christmas.mp4"
          autoPlay
          loop
          muted
          style={{ position: "relative" }}
        />

        <a
          data-event="blackFriday, Only1dollar"
          target="_blank"
          onClick={(e) => postToPage(e)}
          href="https://only1dollardesign.com/"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "13%",
            width: "13%",
            height: "10%",
            background: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        ></a>
        <a
          data-event="blackFriday, OnlyHundred"
          target="_blank"
          onClick={(e) => postToPage(e)}
          href="https://onlyhundred.com/"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "43%",
            width: "20%",
            height: "10%",
            background: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        ></a>
        <a
          target="_blank"
          data-event="blackFriday, RamroDesigns"
          onClick={(e) => postToPage(e)}
          href="https://ramrodesigns.com/"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "75%",
            width: "14%",
            height: "10%",
            border: "none",
            background: "transparent",
            cursor: "pointer",
          }}
        ></a>
        <a
          data-event="blackFriday, Galaincha"
          target="_blank"
          onClick={(e) => postToPage(e)}
          href="https://galaincha.com.np/trial"
          style={{
            position: "absolute",
            bottom: "10px",
            right: "0",
            width: "23%",
            height: "15%",
            border: "none",
            background: "transparent",
            cursor: "pointer",
          }}
        ></a>
        <AiOutlineClose
          className="close-icon"
          onClick={handleClose}
          color="white"
        />
      </Modal> */}
      <div className="section-container" style={{ backgroundColor: "#242424" }}>
        {/* <Header /> */}
        <SectionOne />
        {/* <ExplorugVideo /> */}
        <MyRoomSection />
        <RemodelYourWebsite />
        <RejuvenateYourShowroom />
        <RevolutionalizeYourSocialmediaPresence />
        <ReincarnateyourPromotionalMaterials />
        <RealisticVisualization />

        <TwoScrollButton />
        {/* <explorug-footer
          product="explorug"
          backgroundColor="#3e3e3c"
          themeColor="#00d092"
          titleColor="#d28a5f"
          textColor="white"
          footerURL='{"privacy": "https://explorug.com/PrivacyPolicy.html", "terms": "https://explorug.com/termsofuse.html"}'
        ></explorug-footer> */}
      </div>
    </>
  );
};
export default HomePage;
