import React from "react";
import "./_remodelyourwebsite.css";
import { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { forwardRef } from "react";

const RemodelYourWebsite = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  // formdata1.append("title", document.title);
  formdata1.append("product", "explorug");
  // formdata1.append("ref", "");
  // axios.post(explorugurl, formdata1).then((success) => {});
  function postToPage(e) {
    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }
  function postToPageAndSetShow(e) {
    setShow(true);
    postToPage(e);
  }

  const [successmodel, setSuccessmodel] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const url = "https://explorug.com/archanastools/Utilities/Email.aspx";
  const [data, setData] = useState({
    name: "",
    email: "",
    url: "",
    countrycode: "",
    contact: "",
    notes: "",
    platform: "",
  });
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  function postToPageOnSend(e) {
    formdata1.append(
      "event",
      e.target.getAttribute("data-event") +
        ", " +
        data.name +
        " |" +
        data.email +
        " |" +
        data.website +
        " |" +
        data.countrycode +
        "-" +
        data.contactnumber +
        " |" +
        data.message
    );
    axios.post(explorugurl, formdata1).then((success) => {});
    setData(" ");
  }
  function postToPageOnClick(e) {
    formdata1.delete("event", e.target.getAttribute("data-event"));
    formdata1.append("event", e.target.getAttribute("data-event"));
    axios.post(explorugurl, formdata1).then((success) => {});
  }
  function submit(e) {
    e.preventDefault();

    if (
      data.name &&
      data.email &&
      data.website &&
      data.countrycode &&
      data.contactnumber &&
      data.message
    ) {
      var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      var numberpattern = /^[\d+\-()]+$/;
      var urlpattern =
        /^((https?|ftp):\/\/)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}(\/[^\s]*)?$/i;
      if (!data.email.match(pattern)) {
        var text = document.getElementById("text");
        text.innerHTML = "Please write valid email address.";
        text.style.color = "#ff0000";
        return;
      } else {
        var text = document.getElementById("text");
        text.innerHTML = "";
      }
      if (!data.contactnumber.match(numberpattern)) {
        var numbertext = document.getElementById("numbertext");
        numbertext.innerHTML = "Please write valid contact number.";
        numbertext.style.color = "#ff0000";
        return;
      } else {
        var numbertext = document.getElementById("numbertext");
        numbertext.innerHTML = "";
      }
      if (!data.website.match(urlpattern)) {
        var text = document.getElementById("websitetext");
        text.innerHTML = "Please write valid websiteurl.";
        text.style.color = "#ff0000";
        return;
      } else {
        var text = document.getElementById("websitetext");
        text.innerHTML = "";
      }
      postToPageOnSend(e);
      setLoadingInProgress(true);
      const formdata = new FormData();
      formdata.append("name", data.name);
      formdata.append("email", data.email);
      formdata.append("url", data.website);
      formdata.append("countrycode", data.countrycode);
      formdata.append("contact", data.contactnumber);
      formdata.append("notes", data.message);
      formdata.append("platform", "mockup");
      axios.post(url, formdata).then((returndata) => {
        setLoadingInProgress(false);
        console.log("success");
        setSuccessmodel(true);
      });
    } else {
      alert("Please, fill the empty fields");
    }
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }
  function cancelButtonClick(e) {
    setShow(false);
    setData("");
    postToPageOnClick(e);
  }

  function closeSuccessModal() {
    setSuccessmodel(false);
    setShow(false);
  }
  return (
    <>
      <div
        ref={ref}
        className=" section remodelyourwebsite-wrapper container-fluid text-white d-flex "
      >
        <div className="row">
          <div
            className="remodelyourwebsite-video col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="remodel-h1-mobile">Remodel your website</h1>
            <a
              href="https://explorug.net/ruglife"
              target="_blank"
              title="Click here to view the integration of exploRUG in a website"
            >
              <video
                width="100%"
                autoPlay
                muted
                loop
                poster=""
                data-event="imageClicked,viewIntegration"
                onClick={(e) => postToPageOnClick(e)}
              >
                <source
                  src="https://cdn.explorug.com/website/explorug/RUGLIFE.mp4"
                  type="video/mp4"
                />
                {/* <source src="https://cdn.explorug.com/website/explorug/RUGLIFE.mov" type="video/mov" />
                <source src="https://cdn.explorug.com/website/explorug/RUGLIFE.webm" type="video/webm" /> */}
              </video>
            </a>
            <br />
            <center className="video-description">
              View the integration of exploRUG in a website
            </center>
          </div>
          <div
            className="remodelyourwebsite-text col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="remodel-h1-laptop">Remodel your website</h1>
            <p className="remodel-paragraph-1">
              Your website can be made interactive for visitors
              <br /> so that they customize designs on their own,
              <br /> see in different interiors, and request for
              <br /> quotation
              <br />
            </p>
            <p className="remodel-paragraph-tablet">
              Your website can be made
              <br /> interactive for visitors
              <br /> so that they customize <br />
              designs on their own,
              <br /> see in different interiors, and
              <br /> request for quotation
              <br />
            </p>
            <a
              className="gray"
              href="#"
              data-event="buttonClicked, requestADemo"
              onClick={(e) => postToPageAndSetShow(e)}
            >
              Request a demo
            </a>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        className=" text-white"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ pointerEvents: `${loadingInProgress ? "none" : "auto"}` }}
        >
          <h1 className=" modal-heading text-center">Request a Demo</h1>
          <p className=" modal-paragraph1 text-center ">
            exploRUG can integrate seamlessly into your existing website,
            <br />
            adding customization capabilities to the rugs featured on your
            website.
            <br />
            Before you request a demo, we encourage you to check out our{" "}
            <a
              className="requestademoform-link"
              href="https://explorug.net/ruglife"
              target="_blank"
              data-event="linkClicked,webpageIntegration"
              onClick={(e) => postToPageOnClick(e)}
            >
              webpage integration
            </a>
            &nbsp; <br />
            of exploRUG where each thumbnail is linked to customization
            <br />
            or, you can{" "}
            <a
              className="requestademoform-link"
              href="https://brand.explorug.com/"
              target="_blank"
              data-event="linkClicked,liveDemo"
              onClick={(e) => postToPageOnClick(e)}
            >
              try our live demo.
            </a>
          </p>
          <p className=" modal-paragraph2 text-center">
            Please fill up the form below or send us an email at{" "}
            <a
              className="requestademoform-email"
              href="mailto:info@explorug.net?subject=exploRUG%20demo%20request&amp;body=In%20order%20to%20request%20a%20demo%20with%20your%20own%20designs%2C%20please%20provide%20us%20with%20the%20following%20information%3A%0AYour%20Name%3A%0AEmail%20Address%3A%0ABusiness%20Website%20URL%3A%0AWhatsApp%20Number%3A%0A%0ANotes%3A"
            >
              {" "}
              <b>info@explorug.net</b>
            </a>
            . We will contact you in your WhatsApp Number/ Email.
          </p>
          <form className="modal-form">
            <div className="form-group">
              <input
                value={data.name}
                type="text"
                name="name"
                class="form-control"
                id="name"
                placeholder="Your name"
                required
                onChange={(e) => handle(e)}
              />
            </div>
            <div className="form-group">
              <input
                value={data.email}
                type="email"
                name="email"
                class="form-control"
                id="email"
                placeholder="Email address"
                required
                onChange={(e) => handle(e)}
              />
              <span id="text"> </span>
            </div>
            <div className="form-group">
              <input
                value={data.website}
                type="text"
                class="form-control"
                name="website"
                id="website"
                placeholder="Business Website URL"
                required
                onChange={(e) => handle(e)}
              />
              <span id="websitetext"> </span>
            </div>
            {/* <div className="form-group">
              <input
                value={data.contactnumber}
                type="number"
                name="contactnumber"
                class="form-control"
                id="contactnumber"
                placeholder="WhatsApp Number"
                required
                onChange={(e) => handle(e)}
              />
              <span id="numbertext"> </span>
            </div> */}

            <div className="form-group ">
              <div className="tel-box">
                <div className="select-box form-control ">
                  <select
                    id="countrycode"
                    value={data.countrycode}
                    onChange={(e) => handle(e)}
                    required
                  >
                    <option>Select Country</option>
                    <option
                      data-countryCode="NL"
                      value="Netherlands (+31)"
                      disabled
                      style={{ fontSize: "3px" }}
                    ></option>
                    <option data-countryCode="US" value="United States (+1)">
                      United States (+1)
                    </option>
                    <option data-countryCode="IN" value="India (+91)">
                      India (+91)
                    </option>
                    <option data-countryCode="GB" value="United Kingdom (+44)">
                      United Kingdom (+44)
                    </option>
                    <option data-countryCode="AU" value="Australia (+61)">
                      Australia (+61)
                    </option>
                    <option data-countryCode="NL" value="Netherlands (+31)">
                      Netherlands (+31)
                    </option>
                    <option
                      data-countryCode="NL"
                      value="Netherlands (+31)"
                      disabled
                      style={{ fontSize: "3px" }}
                    ></option>
                    <hr />

                    <option
                      data-countryCode="NL"
                      value="Netherlands (+31)"
                      disabled
                      style={{ fontSize: "3px" }}
                    ></option>
                    <option data-countryCode="AF" value="Afghanistan (+93)">
                      Afghanistan (+93)
                    </option>
                    <option data-countryCode="AL" value="Albania (+355)">
                      Albania (+355)
                    </option>
                    <option data-countryCode="DZ" value="Algeria (+213)">
                      Algeria (+213)
                    </option>
                    <option
                      data-countryCode="AS"
                      value="American Samoa (+1684)
"
                    >
                      American Samoa (+1684)
                    </option>
                    <option data-countryCode="AD" value="Andorra (+376)">
                      Andorra (+376)
                    </option>
                    <option data-countryCode="AO" value="Angola (+244)">
                      Angola (+244)
                    </option>
                    <option data-countryCode="AI" value="Anguilla (+1264)">
                      Anguilla (+1264)
                    </option>
                    <option data-countryCode="AQ" value="Antartica (+672)">
                      Antartica (+672)
                    </option>
                    <option
                      data-countryCode="AG"
                      value="Antigua &amp; Barbuda (+1268)"
                    >
                      Antigua &amp; Barbuda (+1268)
                    </option>
                    <option data-countryCode="AR" value="Argentina (+54)">
                      Argentina (+54)
                    </option>
                    <option data-countryCode="AM" value="Armenia (+374)">
                      Armenia (+374)
                    </option>
                    <option data-countryCode="AW" value="Aruba (+297)">
                      Aruba (+297)
                    </option>
                    <option data-countryCode="AU" value="Australia (+61)">
                      Australia (+61)
                    </option>
                    <option data-countryCode="AT" value="Austria (+43)">
                      Austria (+43)
                    </option>
                    <option data-countryCode="AZ" value="Azerbaijan (+994)">
                      Azerbaijan (+994)
                    </option>
                    <option data-countryCode="BS" value="Bahamas (+1242)">
                      Bahamas (+1242)
                    </option>
                    <option data-countryCode="BH" value="Bahrain (+973)">
                      Bahrain (+973)
                    </option>
                    <option data-countryCode="BD" value="Bangladesh (+880)">
                      Bangladesh (+880)
                    </option>
                    <option data-countryCode="BB" value="Barbados (+1246)">
                      Barbados (+1246)
                    </option>
                    <option data-countryCode="BY" value="Belarus (+375)">
                      Belarus (+375)
                    </option>
                    <option data-countryCode="BE" value="Belgium (+32)">
                      Belgium (+32)
                    </option>
                    <option data-countryCode="BZ" value="Belize (+501)">
                      Belize (+501)
                    </option>
                    <option data-countryCode="BJ" value="Benin (+229)">
                      Benin (+229)
                    </option>
                    <option data-countryCode="BM" value="Bermuda (+1441)">
                      Bermuda (+1441)
                    </option>
                    <option data-countryCode="BT" value="Bhutan (+975)">
                      Bhutan (+975)
                    </option>
                    <option data-countryCode="BO" value="Bolivia (+591)">
                      Bolivia (+591)
                    </option>
                    <option
                      data-countryCode="BA"
                      value="Bosnia &amp; Herzegovina (+387)"
                    >
                      Bosnia &amp; Herzegovina (+387)
                    </option>
                    <option data-countryCode="BW" value="Botswana (+267)">
                      Botswana (+267)
                    </option>
                    <option data-countryCode="BR" value="Brazil (+55)">
                      Brazil (+55)
                    </option>
                    <option
                      data-countryCode="IO"
                      value="British India Ocean Terrirory (+246)"
                    >
                      British India Ocean Terrirory (+246)
                    </option>
                    <option
                      data-countryCode="VG"
                      value="British Virgin Islands (+1284)"
                    >
                      British Virgin Islands (+1284)
                    </option>
                    <option data-countryCode="BN" value="Brunei (+673)">
                      Brunei (+673)
                    </option>
                    <option data-countryCode="BG" value="Bulgaria (+359)">
                      Bulgaria (+359)
                    </option>
                    <option data-countryCode="BF" value="Burkina Faso (+226)">
                      Burkina Faso (+226)
                    </option>
                    <option data-countryCode="BI" value="Burundi (+257)">
                      Burundi (+257)
                    </option>
                    <option data-countryCode="KH" value="Cambodia (+855)">
                      Cambodia (+855)
                    </option>
                    <option data-countryCode="CM" value="Cameroon (+237)">
                      Cameroon (+237)
                    </option>
                    <option data-countryCode="CA" value="Canada (+1)">
                      Canada (+1)
                    </option>
                    <option
                      data-countryCode="CV"
                      value="Cape Verde Islands (+238)"
                    >
                      Cape Verde Islands (+238)
                    </option>
                    <option
                      data-countryCode="KY"
                      value="Cayman Islands (+1345)"
                    >
                      Cayman Islands (+1345)
                    </option>
                    <option
                      data-countryCode="CF"
                      value="Central African Republic (+236)"
                    >
                      Central African Republic (+236)
                    </option>
                    <option data-countryCode="TD" value="Chad (+235)">
                      Chad (+235)
                    </option>
                    <option data-countryCode="CL" value="Chile (+56)">
                      Chile (+56)
                    </option>
                    <option data-countryCode="CN" value="China (+86)">
                      China (+86)
                    </option>
                    <option
                      data-countryCode="CX"
                      value="Christmas Island (+61)"
                    >
                      Christmas Island (+61)
                    </option>
                    <option data-countryCode="CC" value="Cocos Islands (+61)">
                      Cocos Islands (+61)
                    </option>
                    <option data-countryCode="CO" value="Colombia (+57)">
                      Colombia (+57)
                    </option>
                    <option data-countryCode="KM" value="Comoros (+269)">
                      Comoros (+269)
                    </option>
                    <option data-countryCode="CK" value="Cook Islands (+682)">
                      Cook Islands (+682)
                    </option>
                    <option data-countryCode="CR" value="Costa Rica (+506)">
                      Costa Rica (+506)
                    </option>
                    <option data-countryCode="HR" value="Croatia (+385)">
                      Croatia (+385)
                    </option>
                    <option data-countryCode="CU" value="Cuba (+53)">
                      Cuba (+53)
                    </option>
                    <option data-countryCode="CW" value="Curacao (+599)">
                      Curacao (+599)
                    </option>
                    <option data-countryCode="CY" value="Cyprus - North (+90)">
                      Cyprus - North (+90)
                    </option>
                    <option data-countryCode="CY" value="Cyprus - South (+357)">
                      Cyprus - South (+357)
                    </option>
                    <option data-countryCode="CZ" value="Czech Republic (+420)">
                      Czech Republic (+420)
                    </option>
                    <option
                      data-countryCode="CD"
                      value="Democratic Republic of Congo (+243)"
                    >
                      Democratic Republic of Congo (+243)
                    </option>
                    <option data-countryCode="DK" value="Denmark (+45)">
                      Denmark (+45)
                    </option>
                    <option data-countryCode="DJ" value="Djibouti (+253)">
                      Djibouti (+253)
                    </option>
                    <option data-countryCode="DM" value="Dominica (+1809)">
                      Dominica (+1809)
                    </option>
                    <option
                      data-countryCode="DO"
                      value="Dominican Republic (+1809)"
                    >
                      Dominican Republic (+1809)
                    </option>
                    <option data-countryCode="TL" value="East Timor (+670)">
                      East Timor (+670)
                    </option>
                    <option data-countryCode="EC" value="Ecuador (+593)">
                      Ecuador (+593)
                    </option>
                    <option data-countryCode="EG" value="Egypt (+20)">
                      Egypt (+20)
                    </option>
                    <option data-countryCode="SV" value="El Salvador (+503)">
                      El Salvador (+503)
                    </option>
                    <option
                      data-countryCode="GQ"
                      value="Equatorial Guinea (+240)"
                    >
                      Equatorial Guinea (+240)
                    </option>
                    <option data-countryCode="ER" value="Eritrea (+291)">
                      Eritrea (+291)
                    </option>
                    <option data-countryCode="EE" value="Estonia (+372)">
                      Estonia (+372)
                    </option>
                    <option data-countryCode="ET" value="Ethiopia (+251)">
                      Ethiopia (+251)
                    </option>
                    <option
                      data-countryCode="FK"
                      value="Falkland Islands (+500)"
                    >
                      Falkland Islands (+500)
                    </option>
                    <option data-countryCode="FO" value="Faroe Islands (+298)">
                      Faroe Islands (+298)
                    </option>
                    <option data-countryCode="FJ" value="Fiji (+679)">
                      Fiji (+679)
                    </option>
                    <option data-countryCode="FI" value="Finland (+358)">
                      Finland (+358)
                    </option>
                    <option data-countryCode="FR" value="France (+33)">
                      France (+33)
                    </option>
                    <option data-countryCode="GF" value="French Guiana (+594)">
                      French Guiana (+594)
                    </option>
                    <option
                      data-countryCode="PF"
                      value="French Polynesia (+689)"
                    >
                      French Polynesia (+689)
                    </option>
                    <option data-countryCode="GA" value="Gabon (+241)">
                      Gabon (+241)
                    </option>
                    <option data-countryCode="GM" value="Gambia (+220)">
                      Gambia (+220)
                    </option>
                    <option data-countryCode="GE" value="Georgia (+7880)">
                      Georgia (+7880)
                    </option>
                    <option data-countryCode="DE" value="Germany (+49)">
                      Germany (+49)
                    </option>
                    <option data-countryCode="GH" value="Ghana (+233)">
                      Ghana (+233)
                    </option>
                    <option data-countryCode="GI" value="Gibraltar (+350)">
                      Gibraltar (+350)
                    </option>
                    <option data-countryCode="GR" value="Greece (+30)">
                      Greece (+30)
                    </option>
                    <option data-countryCode="GL" value="Greenland (+299)">
                      Greenland (+299)
                    </option>
                    <option data-countryCode="GD" value="Grenada (+1473)">
                      Grenada (+1473)
                    </option>
                    <option data-countryCode="GP" value="Guadeloupe (+590)">
                      Guadeloupe (+590)
                    </option>
                    <option data-countryCode="GU" value="Guam (+671)">
                      Guam (+671)
                    </option>
                    <option data-countryCode="GT" value="Guatemala (+502)">
                      Guatemala (+502)
                    </option>
                    <option data-countryCode="GG" value="Guernsey (+44)">
                      Guernsey (+44)
                    </option>
                    <option data-countryCode="GN" value="Guinea (+224)">
                      Guinea (+224)
                    </option>
                    <option data-countryCode="GW" value="Guinea-Bissau (+245)">
                      Guinea-Bissau (+245)
                    </option>
                    <option data-countryCode="GY" value="Guyana (+592)">
                      Guyana (+592)
                    </option>
                    <option data-countryCode="HT" value="Haiti (+509)">
                      Haiti (+509)
                    </option>
                    <option data-countryCode="HN" value="Honduras (+504)">
                      Honduras (+504)
                    </option>
                    <option data-countryCode="HK" value=" Hong Kong (+852)">
                      Hong Kong (+852)
                    </option>
                    <option data-countryCode="HU" value="Hungary (+36)">
                      Hungary (+36)
                    </option>
                    <option data-countryCode="IS" value="Iceland (+354)">
                      Iceland (+354)
                    </option>
                    <option data-countryCode="IN" value="India (+91)">
                      India (+91)
                    </option>
                    <option data-countryCode="ID" value="Indonesia (+62)">
                      Indonesia (+62)
                    </option>
                    <option data-countryCode="IR" value="Iran (+98)">
                      Iran (+98)
                    </option>
                    <option data-countryCode="IQ" value="Iraq (+964)">
                      Iraq (+964)
                    </option>
                    <option data-countryCode="IE" value="Ireland (+353)">
                      Ireland (+353)
                    </option>
                    <option data-countryCode="IM" value="Isle of Man (+44)">
                      Isle of Man (+44)
                    </option>
                    <option data-countryCode="IL" value="Israel (+972)">
                      Israel (+972)
                    </option>
                    <option data-countryCode="IT" value="Italy (+39)">
                      Italy (+39)
                    </option>
                    <option data-countryCode="CI" value="Ivory Coast (+225)">
                      Ivory Coast (+225)
                    </option>
                    <option data-countryCode="JM" value="Jamaica (+1876)">
                      Jamaica (+1876)
                    </option>
                    <option data-countryCode="JP" value="Japan (+81)">
                      Japan (+81)
                    </option>
                    <option data-countryCode="JE" value="Jersey (+44)">
                      Jersey (+44)
                    </option>
                    <option data-countryCode="JO" value="Jordan (+962)">
                      Jordan (+962)
                    </option>
                    <option data-countryCode="KZ" value="Kazakhstan (+7)">
                      Kazakhstan (+7)
                    </option>
                    <option data-countryCode="KE" value="Kenya (+254)">
                      Kenya (+254)
                    </option>
                    <option data-countryCode="KI" value="Kiribati (+686)">
                      Kiribati (+686)
                    </option>
                    <option data-countryCode="XK" value="Kosovo (+383)">
                      Kosovo (+383)
                    </option>
                    <option data-countryCode="KW" value="Kuwait (+965)">
                      Kuwait (+965)
                    </option>
                    <option data-countryCode="KG" value="Kyrgyzstan (+996)">
                      Kyrgyzstan (+996)
                    </option>
                    <option data-countryCode="LA" value="Laos (+856)">
                      Laos (+856)
                    </option>
                    <option data-countryCode="LV" value="Latvia (+371)">
                      Latvia (+371)
                    </option>
                    <option data-countryCode="LB" value="Lebanon (+961)">
                      Lebanon (+961)
                    </option>
                    <option data-countryCode="LS" value="Lesotho (+266)">
                      Lesotho (+266)
                    </option>
                    <option data-countryCode="LR" value="Liberia (+231)">
                      Liberia (+231)
                    </option>
                    <option data-countryCode="LY" value="Libya (+218)">
                      Libya (+218)
                    </option>
                    <option data-countryCode="LI" value="Liechtenstein (+417)">
                      Liechtenstein (+417)
                    </option>
                    <option data-countryCode="LT" value="Lithuania (+370)">
                      Lithuania (+370)
                    </option>
                    <option data-countryCode="LU" value="Luxembourg (+352)">
                      Luxembourg (+352)
                    </option>
                    <option data-countryCode="MO" value=" Macao (+853)">
                      Macao (+853)
                    </option>
                    <option data-countryCode="MK" value="Macedonia (+389)">
                      Macedonia (+389)
                    </option>
                    <option data-countryCode="MG" value="Madagascar (+261)">
                      Madagascar (+261)
                    </option>
                    <option data-countryCode="MW" value="Malawi (+265)">
                      Malawi (+265)
                    </option>
                    <option data-countryCode="MY" value="Malaysia (+60)">
                      Malaysia (+60)
                    </option>
                    <option data-countryCode="MV" value="Maldives (+960)">
                      Maldives (+960)
                    </option>
                    <option data-countryCode="ML" value="Mali (+223)">
                      Mali (+223)
                    </option>
                    <option data-countryCode="MT" value="Malta (+356)">
                      Malta (+356)
                    </option>
                    <option
                      data-countryCode="MH"
                      value="Marshall Islands (+692)"
                    >
                      Marshall Islands (+692)
                    </option>
                    <option data-countryCode="MQ" value="Martinique (+596)">
                      Martinique (+596)
                    </option>
                    <option data-countryCode="MR" value=" Mauritania (+222)">
                      Mauritania (+222)
                    </option>
                    <option data-countryCode="YT" value="Mayotte (+269)">
                      Mayotte (+269)
                    </option>
                    <option data-countryCode="MX" value="Mexico (+52)">
                      Mexico (+52)
                    </option>
                    <option data-countryCode="FM" value="Micronesia (+691)">
                      Micronesia (+691)
                    </option>
                    <option data-countryCode="MD" value=" Moldova (+373)">
                      Moldova (+373)
                    </option>
                    <option data-countryCode="MC" value=" Monaco (+377)">
                      Monaco (+377)
                    </option>
                    <option data-countryCode="MN" value="Mongolia (+976)">
                      Mongolia (+976)
                    </option>
                    <option data-countryCode="ME" value="Montengro (+382)">
                      Montengro (+382)
                    </option>
                    <option data-countryCode="MS" value=" Montserrat (+1664)">
                      Montserrat (+1664)
                    </option>
                    <option data-countryCode="MA" value="Morocco (+212)">
                      Morocco (+212)
                    </option>
                    <option data-countryCode="MZ" value="Mozambique (+258)">
                      Mozambique (+258)
                    </option>
                    <option data-countryCode="MN" value="Myanmar (+95)">
                      Myanmar (+95)
                    </option>
                    <option data-countryCode="NA" value="Namibia (+264)">
                      Namibia (+264)
                    </option>
                    <option data-countryCode="NR" value="Nauru (+674)">
                      Nauru (+674)
                    </option>
                    <option data-countryCode="NP" value="Nepal (+977)">
                      Nepal (+977)
                    </option>
                    <option data-countryCode="NL" value="Netherlands (+31)">
                      Netherlands (+31)
                    </option>
                    <option
                      data-countryCode="AN"
                      value="Netherlands Antilles (+599)"
                    >
                      Netherlands Antilles (+599)
                    </option>
                    <option data-countryCode="NC" value="New Caledonia (+687)">
                      New Caledonia (+687)
                    </option>
                    <option data-countryCode="NZ" value="New Zealand (+64)">
                      New Zealand (+64)
                    </option>
                    <option data-countryCode="NI" value="Nicaragua (+505)">
                      Nicaragua (+505)
                    </option>
                    <option data-countryCode="NE" value="Niger (+227)">
                      Niger (+227)
                    </option>
                    <option data-countryCode="NG" value="Nigeria (+234)">
                      Nigeria (+234)
                    </option>
                    <option data-countryCode="NU" value="Niue (+683)">
                      Niue (+683)
                    </option>
                    <option data-countryCode="KP" value="North Korea (+850)">
                      North Korea (+850)
                    </option>
                    <option
                      data-countryCode="NF"
                      value="Norfolk Islands (+672)"
                    >
                      Norfolk Islands (+672)
                    </option>
                    <option
                      data-countryCode="NP"
                      value="Northern Marianas (+670)"
                    >
                      Northern Marianas (+670)
                    </option>
                    <option data-countryCode="NO" value="Norway (+47)">
                      Norway (+47)
                    </option>
                    <option data-countryCode="OM" value="Oman (+968)">
                      Oman (+968)
                    </option>
                    <option data-countryCode="PK" value="Pakistan (+92)">
                      Pakistan (+92)
                    </option>
                    <option data-countryCode="PW" value="Palau (+680)">
                      Palau (+680)
                    </option>
                    <option data-countryCode="PS" value="Palestine (+970)">
                      Palestine (+970)
                    </option>
                    <option data-countryCode="PA" value=" Panama (+507)">
                      Panama (+507)
                    </option>
                    <option
                      data-countryCode="PG"
                      value=" Papua New Guinea (+675)"
                    >
                      Papua New Guinea (+675)
                    </option>
                    <option data-countryCode="PY" value="Paraguay (+595)">
                      Paraguay (+595)
                    </option>
                    <option data-countryCode="PE" value="Peru (+51)">
                      Peru (+51)
                    </option>
                    <option data-countryCode="PH" value="Philippines (+63)">
                      Philippines (+63)
                    </option>
                    <option data-countryCode="PN" value="Pitcairn (+64)">
                      Pitcairn (+64)
                    </option>
                    <option data-countryCode="PL" value="Poland (+48)">
                      Poland (+48)
                    </option>
                    <option data-countryCode="PT" value="Portugal (+351)">
                      Portugal (+351)
                    </option>
                    <option data-countryCode="PR" value="Puerto Rico (+1787)">
                      Puerto Rico (+1787)
                    </option>
                    <option data-countryCode="QA" value="Qatar (+974)">
                      Qatar (+974)
                    </option>
                    <option
                      data-countryCode="CG"
                      value="Republic of the Congo (+242)"
                    >
                      Republic of the Congo (+242)
                    </option>
                    <option data-countryCode="RE" value="Reunion (+262)">
                      Reunion (+262)
                    </option>
                    <option data-countryCode="RO" value="Romania (+40)">
                      Romania (+40)
                    </option>
                    <option data-countryCode="RU" value="Russia (+7)">
                      Russia (+7)
                    </option>
                    <option data-countryCode="RW" value="Rwanda (+250)">
                      Rwanda (+250)
                    </option>
                    <option
                      data-countryCode="BL"
                      value="Saint Barthelemy (+590)"
                    >
                      Saint Barthelemy (+590)
                    </option>
                    <option data-countryCode="SH" value="Saint Helena (+290)">
                      Saint Helena (+290)
                    </option>
                    <option
                      data-countryCode="KN"
                      value="Saint Kitts &amp; Nevis (+1869)"
                    >
                      Saint Kitts &amp; Nevis (+1869)
                    </option>
                    <option data-countryCode="SC" value="Saint Lucia (+1758)">
                      Saint Lucia (+1758)
                    </option>
                    <option data-countryCode="SR" value="Suriname (+597)">
                      Suriname (+597)
                    </option>
                    <option data-countryCode="MF" value="Saint Martin (+590)">
                      Saint Martin (+590)
                    </option>
                    <option
                      data-countryCode="PM"
                      value="Saint Saint Pierre and Miquelon (+508)"
                    >
                      Saint Saint Pierre and Miquelon (+508)
                    </option>
                    <option
                      data-countryCode="VC"
                      value="Saint Vincent and the Grenadines (+1784)"
                    >
                      Saint Vincent and the Grenadines (+1784)
                    </option>
                    <option data-countryCode="WS" value=" Samoa (+685)">
                      Samoa (+685)
                    </option>
                    <option data-countryCode="SM" value="San Marino (+378)">
                      San Marino (+378)
                    </option>
                    <option
                      data-countryCode="ST"
                      value="Sao Tome &amp; Principe (+239)"
                    >
                      Sao Tome &amp; Principe (+239)
                    </option>
                    <option data-countryCode="SA" value="Saudi Arabia (+966)">
                      Saudi Arabia (+966)
                    </option>
                    <option data-countryCode="SN" value="Senegal (+221)">
                      Senegal (+221)
                    </option>
                    <option data-countryCode="CS" value="Serbia (+381)">
                      Serbia (+381)
                    </option>
                    <option data-countryCode="SC" value="Seychelles (+248)">
                      Seychelles (+248)
                    </option>
                    <option data-countryCode="SL" value="Sierra Leone (+232)">
                      Sierra Leone (+232)
                    </option>
                    <option data-countryCode="SG" value="Singapore (+65)">
                      Singapore (+65)
                    </option>
                    <option data-countryCode="SX" value="Sint Maarten (+1721)">
                      Sint Maarten (+1721)
                    </option>
                    <option data-countryCode="SK" value="Slovakia (+421)">
                      Slovakia (+421)
                    </option>
                    <option data-countryCode="SI" value="Slovenia (+386)">
                      Slovenia (+386)
                    </option>
                    <option
                      data-countryCode="SB"
                      value="Solomon Islands (+677)"
                    >
                      Solomon Islands (+677)
                    </option>
                    <option data-countryCode="SO" value="Somalia (+252)">
                      Somalia (+252)
                    </option>
                    <option data-countryCode="ZA" value="South Africa (+27)">
                      South Africa (+27)
                    </option>
                    <option data-countryCode="KR" value="South Korea (+82)">
                      South Korea (+82)
                    </option>
                    <option data-countryCode="SS" value="South Sudan (+211)">
                      South Sudan (+211)
                    </option>
                    <option data-countryCode="ES" value="Spain (+34)">
                      Spain (+34)
                    </option>
                    <option data-countryCode="LK" value="Sri Lanka (+94)">
                      Sri Lanka (+94)
                    </option>
                    <option data-countryCode="SD" value="Sudan (+249)">
                      Sudan (+249)
                    </option>
                    <option data-countryCode="SR" value="Suriname (+597)">
                      Suriname (+597)
                    </option>
                    <option
                      data-countryCode="SJ"
                      value="Svalbard &amp; Jan Mayen (+47)"
                    >
                      Svalbard &amp; Jan Mayen (+47)
                    </option>
                    <option data-countryCode="SZ" value="Swaziland (+268)">
                      Swaziland (+268)
                    </option>
                    <option data-countryCode="SE" value="Sweden (+46)">
                      Sweden (+46)
                    </option>
                    <option data-countryCode="CH" value="Switzerland (+41)">
                      Switzerland (+41)
                    </option>
                    <option data-countryCode="SY" value="Syria (+963)">
                      Syria (+963)
                    </option>
                    <option data-countryCode="TW" value="Taiwan (+886)">
                      Taiwan (+886)
                    </option>
                    <option data-countryCode="TJ" value="Tajikistan (+992)">
                      Tajikistan (+992)
                    </option>
                    <option data-countryCode="TZ" value="Tanzania (+255)">
                      Tanzania (+255)
                    </option>
                    <option data-countryCode="TH" value="Thailand (+66)">
                      Thailand (+66)
                    </option>
                    <option data-countryCode="TG" value="Togo (+228)">
                      Togo (+228)
                    </option>
                    <option data-countryCode="TO" value="Tonga (+676)">
                      Tonga (+676)
                    </option>
                    <option
                      data-countryCode="TT"
                      value="Trinidad &amp; Tobago (+1868)"
                    >
                      Trinidad &amp; Tobago (+1868)
                    </option>
                    <option data-countryCode="TN" value="Tunisia (+216)">
                      Tunisia (+216)
                    </option>
                    <option data-countryCode="TR" value="Turkey (+90)">
                      Turkey (+90)
                    </option>
                    <option data-countryCode="TM" value="Turkmenistan (+993)">
                      Turkmenistan (+993)
                    </option>
                    <option
                      data-countryCode="TC"
                      value="Turks &amp; Caicos Islands (+1649)"
                    >
                      Turks &amp; Caicos Islands (+1649)
                    </option>
                    <option data-countryCode="TV" value="Tuvalu (+688)">
                      Tuvalu (+688)
                    </option>
                    <option data-countryCode="UG" value="Uganda (+256)">
                      Uganda (+256)
                    </option>
                    <option data-countryCode="UA" value="Ukraine (+380)">
                      Ukraine (+380)
                    </option>
                    <option
                      data-countryCode="AE"
                      value="United Arab Emirates (+971)"
                    >
                      United Arab Emirates (+971)
                    </option>
                    <option data-countryCode="GB" value="United Kingdom (+44)">
                      United Kingdom (+44)
                    </option>
                    <option data-countryCode="US" value="United States (+1)">
                      United States (+1)
                    </option>
                    <option data-countryCode="UY" value="Uruguay (+598)">
                      Uruguay (+598)
                    </option>
                    <option data-countryCode="UZ" value="Uzbekistan (+998)">
                      Uzbekistan (+998)
                    </option>
                    <option data-countryCode="VU" value="Vanuatu (+678)">
                      Vanuatu (+678)
                    </option>
                    <option data-countryCode="VA" value="Vatican City (+379)">
                      Vatican City (+379)
                    </option>
                    <option data-countryCode="VE" value="Venezuela (+58)">
                      Venezuela (+58)
                    </option>
                    <option data-countryCode="VN" value="Vietnam (+84)">
                      Vietnam (+84)
                    </option>
                    <option
                      data-countryCode="WF"
                      value="Wallis &amp; Futuna (+681)"
                    >
                      Wallis &amp; Futuna (+681)
                    </option>
                    <option data-countryCode="YE" value="Yemen (North)(+969)">
                      Yemen (North)(+969)
                    </option>
                    <option data-countryCode="YE" value="Yemen (South)(+967)">
                      Yemen (South)(+967)
                    </option>
                    <option data-countryCode="ZM" value="Zambia (+260)">
                      Zambia (+260)
                    </option>
                    <option data-countryCode="ZW" value="Zimbabwe (+263)">
                      Zimbabwe (+263)
                    </option>
                  </select>
                </div>
                <input
                  value={data.contactnumber}
                  className="tel"
                  type="number"
                  name="contactnumber"
                  class="form-control enumber"
                  id="contactnumber"
                  placeholder="WhatsApp Number"
                  onChange={(e) => handle(e)}
                />
                <span id="numbertext"> </span>
              </div>
            </div>
            <div className="form-group">
              <input
                value={data.message}
                type="text"
                class="form-control"
                id="message"
                name="message"
                placeholder="Notes"
                required
                onChange={(e) => handle(e)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            data-event="buttonClicked, requestADemoCancel"
            onClick={(e) => cancelButtonClick(e)}
          >
            CANCEL
          </Button>
          <Button
            className="send-button"
            value="send"
            id="send"
            data-event="requestADemoClicked"
            onClick={(e) => submit(e)}
          >
            SEND
            {loadingInProgress && (
              <span
                className="spinner-border spinner-border-sm mx-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={successmodel}
        fullscreen={fullscreen}
        className=" modal-success text-white"
      >
        {/* <i class="fa-sharp fa-solid fa-xmark"></i> */}
        <Modal.Body>
          <button
            type="button"
            className=" success-close btn-close"
            aria-label="Close"
            onClick={(e) => closeSuccessModal()}
          ></button>
          <h1 className=" modal-heading text-center">Request a Demo</h1>
          <p className=" modalsuccess-paragraph text-center ">
            Thank you for your response.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default RemodelYourWebsite;
