import React from "react";
import { forwardRef } from "react";
import axios from "axios";
import "./_reincarnateyourpromotionalmaterials.css";

const ReincarnateyourPromotionalMaterials = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.delete("event",e.target.getAttribute("data-event"));
    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }
  return (
    <>
      <div ref={ref} className=" section reincarnate-wrapper text-white d-flex">
        <div className="row">
          <div
            className="reincarnate-image col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="reincarnate-h1-mobile">
              Reincarnate your
              <br />
              promotional materials
            </h1>
            <a href="https://go.explorug.com/explorug_brochure">
              <img
                className="reincarnate-img"
                src="https://cdn.explorug.com/website/explorug/exploRUG-Brochure-with-poms-min.jpg"
                data-event="imageClicked, Brochure" 
                onClick={(e) => postToPage(e)}/>
            </a>
            <center className="reincarnate-image-text">
              Scan the QR code to customize the design
            </center>
          </div>
          <div
            className="reincarnate-text col-md-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="reincarnate-h1-laptop">
              Reincarnate your
              <br />
              promotional materials
            </h1>
            <p className="reincarnate-para-1">
              Product catalogs, brochures and printed ads
              <br />
              can be digitally connected to exploRUG,
              <br />
              giving a new ability to customize directly
              <br />
              from printed media
            </p>
            <p className="reincarnate-para-tablet">
              Product catalogs, brochures
              <br /> and printed ads
              <br />
              can be digitally connected to <br /> exploRUG,
              <br />
              giving a new ability to <br /> customize directly
              <br />
              from printed media
            </p>
            <a
              className="gray"
              href="https://go.explorug.com/explorug_brochure"
              target="_blank"
              data-event="linkClicked, dwdBrochure"
              onClick={(e) => postToPage(e)}
            >
              {" "}
              Download the brochure to try yourself
            </a>
          </div>
        </div>
      </div>
    </>
  );
});

export default ReincarnateyourPromotionalMaterials;
