import React from "react";
import axios from "axios";
import { forwardRef } from "react";
import "./_realisticvisualization.css";
const RealisticVisualization = forwardRef((props, ref) => {
  const explorugurl =
    "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
  var formdata1 = new FormData();
  formdata1.append("product", "explorug");
  function postToPage(e) {
    formdata1.append("event", e.target.getAttribute("data-event"));

    axios.post(explorugurl, formdata1).then((success) => {});
  }
  return (
    <>
      <div ref={ref} className="realistic-wrapper">
        <div id="contactus" className=" section realistic-image">
          {/* <img className="w-100 realistic-image"  src="https://cdn.explorug.com/website/explorug/Before-After-min.jpg"/> */}
          <div
            className=" realistic-text-one text-white text-center"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="sellyourrug"> Sell your rug before it is made</h1>
            <p className="realistic-para1">
              {" "}
              Our technology helps you sell your custom rug first and then{" "}
              <br />
              produce it exactly as it was shown.
            </p>
            <p className="realistic-para">
              {" "}
              Our technology helps you sell your custom rug first and then
              produce it exactly as it was shown.
            </p>
            <a
              className="realistic-email text-white"
              href="https://alternative.com.np/#/contact-us"
              data-event="buttonClicked, emailUs"
              target="_blank"
              onClick={(e) => {
                postToPage(e);
              }}
            >
              EMAIL US
            </a>
          </div>
          <div
            className="realistic-text-one-tablet text-white"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <h1 className="sellyourrug-tablet ">
              {" "}
              Sell your rug before it is made
            </h1>
            <p className="realistic-para1-tablet">
              {" "}
              Our technology helps you sell your <br /> custom rug first and
              then <br /> produce it exactly as it was shown.
            </p>

            <a
              className="realistic-email-tablet text-white"
              href="mailto: info@explorug.net "
              data-event="buttonClicked, emailUs"
              onClick={(e) => {
                postToPage(e);
              }}
            >
              EMAIL US
            </a>
          </div>
          {/* <div
            className="realistic-text-two text-white"
            data-aos="fade-up"
            data-aos-offset="250"
            data-aos-delay="1000"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <div className="softwarerug">
              Realistic visualization from the software
            </div>
            <div className="actualrug">Actual produced rug after 3 months</div>
          
          </div> */}

          {/* <div
            className="realistic-text-two text-white"
            data-aos="fade-up"
            data-aos-offset="250"
            data-aos-delay="1000"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <p className="visualizationfromsoftware">
              Realistic visualization from the software
            </p>
            <p className="visualizationfromactualrug">
              Actual produced rug after 3 months
            </p>
          </div> */}
          <div
            class="realistic-text-two text-white aos-init aos-animate"
            data-aos="fade-up"
            data-aos-offset="250"
            data-aos-delay="1000"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            Realistic visualization from the software
            <br />
            <br />
            Actual produced rug after 3 months
          </div>
        </div>
      </div>
    </>
  );
});

export default RealisticVisualization;
