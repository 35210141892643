import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";

import Header from "./components/views/ExplorugHeader/explorugheader";

import "./App.css";
import HomePage from "./Pages/Homepage";
import ContactPage from "./Pages/ContactPage";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>

      <explorug-footer
        product="explorug"
        backgroundColor="#3e3e3c"
        themeColor="#00d092"
        titleColor="#d28a5f"
        textColor="white"
        footerURL='{"privacy": "https://explorug.com/PrivacyPolicy.html", "terms": "https://explorug.com/termsofuse.html"}'
      ></explorug-footer>
    </>
  );
};
export default App;
